// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bravo-tsx": () => import("./../../../src/pages/bravo.tsx" /* webpackChunkName: "component---src-pages-bravo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legals-tsx": () => import("./../../../src/pages/legals.tsx" /* webpackChunkName: "component---src-pages-legals-tsx" */),
  "component---src-pages-message-tsx": () => import("./../../../src/pages/message.tsx" /* webpackChunkName: "component---src-pages-message-tsx" */),
  "component---src-pages-send-tsx": () => import("./../../../src/pages/send.tsx" /* webpackChunkName: "component---src-pages-send-tsx" */),
  "component---src-pages-visual-tsx": () => import("./../../../src/pages/visual.tsx" /* webpackChunkName: "component---src-pages-visual-tsx" */)
}

